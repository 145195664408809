const Data = [
  {
    q: "WHY CHOOSE US",
    Subq: "Sed mollis lorem ipsum dolor sit amet, consectetuer adipiscing elit vamus fringilla sed cipit mil sed hac habitasse platea dictumst pellentesque justo.",
    underline:
      "Aliquam malesuada mauris urk metus lorem ipsum dolor sit amet consectetur adipisicing    ",
    a: "Vivamus quis sapien. Etiam fring illatul Quisque consectetuer. Integer conguel elit non semper laoreet sed lectus orcid posuere nisl tempor lacus felis ac mauris.Vel magna posuere vestibulum. Nam rutrum congue diam. Pellentesque inedu urna. Integer lvitae felis. Vel magna posuere vestibulum. Nam rutrum congue diam.",
  },
  {
    q: "THE MISSION",
    Subq: "Aliquam malesuada mauris urk metus lorem ipsum dolor sit amet consectetur adipisicingSed mollis lorem ipsum dolor sit amet, consectetuer adipiscing elit vamus fringilla sed cipit mil sed hac habitasse platea dictumst pellentesque justo.",
    underline:
      "Aliquam malesuada mauris urk metus lorem ipsum dolor sit amet consectetur adipisicing",
    a: "Vivamus quis sapien. Etiam fring illatul Quisque consectetuer. Integer conguel elit non semper laoreet sed lectus orcid posuere nisl tempor lacus felis ac mauris.Vel magna posuere vestibulum. Nam rutrum congue diam. Pellentesque inedu urna. Integer lvitae felis. Vel magna posuere vestibulum. Nam rutrum congue diam.",
  },
  {
    q: "OUR STRATEGY",
    Subq: "Vel magna posuere vestibulum. Nam rutrum congue diam.",
    underline:
      "Aliquam malesuada mauris urk metus lorem ipsum dolor sit amet consectetur adipisicing",
    a: "Vivamus quis sapien. Etiam fring illatul Quisque consectetuer. Integer conguel elit non semper laoreet sed lectus orcid posuere nisl tempor lacus felis ac mauris.Vel magna posuere vestibulum. Nam rutrum congue diam. Pellentesque inedu urna. Integer lvitae felis. Vel magna posuere vestibulum. Nam rutrum congue diam.",
  },
];

export default Data;
