const Datas = [
  {
    q: "What is FXCopier",
    p: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum dolore nisi commodi at optio error assumenda maiores? Aperiam delectus, soluta nobis totam, dolore vitae veniam inventore voluptas obcaecati, magnam et?",
  },
  {
    q: "what is the right plan for me",
    p: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum dolore nisi commodi at optio error assumenda maiores? Aperiam delectus, soluta nobis totam, dolore vitae veniam inventore voluptas obcaecati, magnam et?",
  },
];

export default Datas;
