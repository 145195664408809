const WareHouseData = [
  {
    q: "Which Plan Is Right For Me?",
    a: "Our skilled personnel, utilising the latest communications, tracking and processing software, combined with decades of experience, ensure all freight is are shipped, trans-shipped and delivered as safely.        ",
  },
  {
    q: "Do I have to commit to a contract?",
    a: "Our skilled personnel, utilising the latest communications, tracking and processing software, combined with decades of experience, ensure all freight is are shipped, trans-shipped and delivered as safely.",
  },
  {
    q: " What Payment Methods Are Available?",
    a: "Our skilled personnel, utilising the latest communications, tracking and processing software, combined with decades of experience, ensure all freight is are shipped, trans-shipped and delivered as safely.",
  },
];

export default WareHouseData;
